@charset "utf-8";

.cat-tabs.region ul li {
	flex: 1 1 auto;
}

/* TOP - ODEKAKE
____________________________________________________ */
.odekakeplus {
	background: url(../img/bgstripe.png);
	padding: 2rem 0 !important;
	width: 100%;
	max-width: 100%;
	margin-bottom: 3em;
}
.odekakeplus .inner {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: flex-end;
}

.odekakeplus .inner .carousel-wrap {
	width: 100%;
	margin-bottom: 2px;
	background: #f1f1f1;
  aspect-ratio: 4 / 3;
}
.odekakeplus .inner .carousel {
	width: 100%;
	height: 100%;
}
.odekakeplus .inner .carousel .item {
	display: block;
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
}
.odekakeplus .inner .carousel .item .thumb {
	width: 100%;
	height: 100%;
  aspect-ratio: 4 / 3;
}
.odekakeplus .inner .carousel .item .detail {
	width: 86%;
	padding: 1.5rem 1.5rem 1.25rem 1.5rem;
	background: rgba(255,255,255,0.75);
	position: absolute;
	left: 0;
	bottom: 2em;
	z-index: 10;
}
.odekakeplus .inner .carousel .item .detail .meta-cat {
	position: absolute;
	bottom: calc( 100% - 0.6em );
	left: 0px;
	background: rgba(var(--color05),1);
	color: rgba(var(--color08),1);
	line-height: 1;
	padding: 0.35em 1em;
}
.odekakeplus .inner .item .detail .caption {
	font-size: min(4.5vw, 22px);
	font-weight: 700;
	color: rgba(var(--color00),0.85);
	line-height: 1.2;
}

.odekakeplus .inner .article-list {
	background: #fff;
	margin-bottom: 2px;
}
.odekakeplus .inner .article-list li {
	background: #f1f1f1;
	margin: 0 1px;
}
.odekakeplus .inner .article-list:nth-of-type(1) {
	width: 100%;
}
.odekakeplus .inner .article-list:nth-of-type(2) {
	width: 100%;
}
.odekakeplus .inner .article-list:nth-of-type(1) li {
	flex: 0 0 calc( 100% / 2 - 2px );
}
.odekakeplus .inner .article-list:nth-of-type(2) li {
	flex: 0 0 calc( 100% / 2 - 2px );
}
.odekakeplus .inner .article-list:nth-of-type(1) li:last-child,
.odekakeplus .inner .article-list:nth-of-type(2) li:last-child {
	display: block;
}

@media ( min-width: 641px ) {
	.odekakeplus .inner .carousel-wrap {
		width: 100%;
	}
	.odekakeplus .inner .article-list:nth-of-type(1) {
		width: 100%;
	}
	.odekakeplus .inner .article-list:nth-of-type(2) {
		width: 100%;
	}
	.odekakeplus .inner .article-list:nth-of-type(1) li {
		flex: 0 0 calc( 100% / 3 - 2px );
	}
	.odekakeplus .inner .article-list:nth-of-type(2) li {
		flex: 0 0 calc( 100% / 3 - 2px );
	}
	.odekakeplus .inner .article-list:nth-of-type(1) li:last-child,
	.odekakeplus .inner .article-list:nth-of-type(2) li:last-child {
		display: none;
	}
}
@media ( min-width: 834px ) {
	.odekakeplus .inner .carousel-wrap {
		width: 50%;
    aspect-ratio: 400 / 334;
	}
  .odekakeplus .inner .carousel .item .thumb {
    aspect-ratio: 400 / 334;
  }
	.odekakeplus .inner .article-list:nth-of-type(1) {
		width: 50%;
	}
	.odekakeplus .inner .article-list:nth-of-type(2) {
		width: 100%;
	}
	.odekakeplus .inner .article-list:nth-of-type(1) li {
		flex: 0 0 calc( 100% / 2 - 2px );
	}
	.odekakeplus .inner .article-list:nth-of-type(2) li {
		flex: 0 0 calc( 100% / 4 - 2px );
	}
	.odekakeplus .inner .article-list:nth-of-type(1) li:last-child,
	.odekakeplus .inner .article-list:nth-of-type(2) li:last-child {
		display: block;
	}
}
@media ( min-width: 1281px ) {
	.slick-dotted.slick-slider {
		margin-bottom: 0 !important;
	}
	.odekakeplus .inner .carousel-wrap {
		width: 40%;
		aspect-ratio: 4 / 3;
		margin-bottom: 0;
	}
  .odekakeplus .inner .carousel .item .thumb {
    aspect-ratio: 40 / 31;
  }
	.odekakeplus .inner .article-list:nth-of-type(1) {
		width: 30%;
	}
	.odekakeplus .inner .article-list:nth-of-type(2) {
		width: 30%;
	}
	.odekakeplus .inner .article-list:nth-of-type(1) li {
		flex: 0 0 calc( 100% / 2 - 2px);
	}
	.odekakeplus .inner .article-list:nth-of-type(2) li {
		flex: 0 0 calc( 100% / 2 - 2px);
	}
}

.topictags {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	padding: 1rem 0;
	line-height: 1;
	width: 100%;
}
.topictags span {
	font-weight: 700;
	color: var(--color00);
	font-size: min(3.5vw, 14px);
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
}
.topictags a {
	font-size: min(3.5vw, 14px);
	padding: 0.25em 1.5em;
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
	letter-spacing: 0;
	color: rgba(var(--color01), 1);
	background: rgba(var(--color02), 1);
	border-radius: 2em;
	font-weight: 700;
}


/* LIST RELATION SITES
____________________________________________________ */
.relation-wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;
}
.relation-wrap .item {
  margin-bottom: 2rem;
	flex: 0 0 calc(100% / 2);
}
.relation-wrap .item .relation-headline {
  font-weight: 700;
  font-size: min(3.5vw, 13px);
  margin-bottom: 1rem;
	min-height: 1em;
}
.relation-wrap ul li {
  padding: 0.5em 0;
}
.relation-wrap ul li a {
  color: rgba(var(--color01),1);
  font-size: min(3vw, 12px);
  font-weight: 700;
}
.relation-banners {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.relation-banners a {
  margin-right: 1rem;
  margin-bottom: 1rem;
  flex: auto;
}
@media ( min-width: 641px ) {
	.relation-wrap .item {
		flex: 0 0 calc(100% / 3 );
  }
}
@media ( min-width: 1281px ) {
  .relation-wrap {
    flex-direction: row;
  }
  .relation-wrap .item {
    margin-bottom: 3em;
		flex: 0 0 calc(100% / 5);
  }
  .relation-banners a {
    flex: none;
  }
}

/* open-close */
.js-toggle-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.js-toggle-btn::after {
  content: '';
  display: block;
  padding-right: 1.75em;
  height: min(5vw, 13px);
  margin-right: 1rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.25s ease-out;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.81 13.29"><path d="M18.39,3.08l-7.48,9.49c-.76,.96-2.22,.97-2.99,0L.41,3.08C-.57,1.84,.32,0,1.91,0h14.99c1.59,0,2.48,1.83,1.49,3.08Z" fill="%233471d4"/></svg>');
}
.js-toggle-btn.open::after {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.2941 11.3277"><path d="M.3,8.9022,6.409.6186A1.5221,1.5221,0,0,1,8.8571.6161L14.9925,8.9a1.5221,1.5221,0,0,1-1.2231,2.428H1.5248A1.5221,1.5221,0,0,1,.3,8.9022Z" fill="%233471d4"/></svg>');
}



.js-toggle-content {
  display: block;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  height: 0;
  pointer-events: none;
  transition: opacity 0.1s ease, visibility 0.1s ease;
}
.js-toggle-content.open {
  opacity: 1;
  visibility: visible;
  height: auto;
  pointer-events: auto;
  transition: opacity 1s ease, visibility 0.5s ease;
}

/* TEXT Ad for MediaBusiness
____________________________________________________ */
.text2 {
  font-size: min(3.5vw, 13px);
  font-weight: 700;
  color: rgba(var(--color01),1);
  padding: 0.5rem 0 0.5rem 0.75rem;
  letter-spacing: 0;
}
.text2::before{
  content: "PR　";
}
.text2 a:link, .text2 a:visited, .text2 a:hover, .text2 a:active {
  color: rgba(var(--color01),1)
}
